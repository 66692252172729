@media only screen and (max-width: 630px) {
  body {
    overflow-x: initial;
  }

  h1 {
    font-size: 1.3em;
  }

  #nisa {
    max-width: 90px;
    max-height: 45px;
    margin: 10px;
  }

  #title {
    font-family: caslon;

    width: 100%;
    text-align: center;
    font-size: 3em;
    color: white;
    text-shadow: black 5px 5px 5px;
    z-index: 2;
    margin-top: initial;
  }

  .hide-on-mobile {
    display: none;
  }

  .hide-on-desktop {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .small-covers {
    z-index: 0;
    /* height: 100vh; */
  }

  .small-cover {
    width: 95vw;
    min-width: 300px;
    max-width: 500px;
    margin: 0;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.747);
  }

  #main {
    justify-content: flex-start;
    height: initial;
    margin-bottom: 100px;
  }

  .brand-icon {
    width: 84px;
    margin: 8px;
  }

  .bg-overlay {
    height: 100vh !important;
    width: auto !important;

    /* height: 100%; */
    position: fixed;

    top: 70;
    left: 0;

    transition: 6s linear;

    transform-origin: top left;
  }

  .mobile-banner {
    background-color: #911b23;
    background-color: #5375b3;
    color: white;

    margin: 0;
    font-family: body;
    padding: 8px;
  }
}
