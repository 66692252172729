@font-face {
  font-family: optimus;
  src: url("./assets/fonts/OptimusPrinceps/OptimusPrinceps.ttf");
}

@font-face {
  font-family: caslon;
  src: url("./assets/fonts/caslon-antique/CaslonAntique-Bold.ttf");
}

@font-face {
  font-family: body;
  src: url("./assets/fonts/dustismo_roman/Dustismo_Roman.ttf");
}

@font-face {
  font-family: SCE;
  src: url("./assets/fonts/SCE3.7W1.TTF");
}

@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

.news-letter {
  font-family: optimus;
  margin: 32px 0;
  text-align: center;
}

.nl-btn {
  padding: 8px;
  background-color: rgb(63, 63, 63);
  color: white;
  margin-top: 8px;
  margin-bottom: 32px;
  cursor: pointer;
  border-radius: 0;
  -webkit-appearance: none;
  font-size: 1rem;
}

.nl-btn:hover {
  background-color: rgb(99, 99, 99);
}

.nl-email {
  width: 300px;
  height: 32px;
  background-color: rgb(39, 27, 10);
  color: white;
  font-size: 1rem;
}

body {
  overflow-x: hidden;
}

.hide-on-desktop {
  display: none !important;
}

p {
  font-family: caslon;
  font-size: 2em;
  color: white;
  text-align: center;
}

.top-btn {
  background-color: #911b23dc;
  color: white;
  font-family: caslon;
  font-size: 1.3em;
  padding: 8px;
  margin: 8px;
  cursor: pointer;
}

.App {
  width: 100%;
  min-height: 100vh;

  overflow: hidden;
  /* background-image: linear-gradient(to bottom, #171b38, #623b85, #7989c9); */
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.start {
  justify-content: flex-start;
}

.wrap {
  flex-wrap: wrap;
}

.apart {
  justify-content: center;
}

.bgs {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 70px;
  background-color: #000000;
  /* z-index: -2; */
}

.bgs2 {
  width: 100vw;

  overflow: hidden;
  position: fixed;
  top: 50vh;
  background-color: #0a254d;
}

.bg-overlay {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  position: fixed;

  top: 70;
  left: 0;

  transition: 6s linear;
  transition-property: opacity, transform;

  transform-origin: top left;
}

.page-overlay {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 70px;
  z-index: 0;
  opacity: 0.5;
  transition: 0.2s ease-in;
  background-position: center;
}

#header {
  width: 100%;
  height: 70px;
  background-color: black;
  position: fixed;
  z-index: 3;
}

#nisa {
  max-width: 150px;
  max-height: 45px;
  margin: 10px;
}

#header-title {
  color: white;
  margin-left: auto;
  margin-right: 8px;
  font-family: optimus;
}

#main {
  width: 100%;
  /* height: 100%; */
  height: 800px;

  /* z-index: -1; */
}

#title {
  font-family: caslon;

  width: 100%;
  text-align: center;
  font-size: 4em;
  color: white;
  text-shadow: black 5px 5px 5px;
  z-index: 2;
}

.covers {
  /* width: 100%; */
  /* height: 100vh; */
  /* width: 900px; */
  z-index: 2;
  margin-top: 150px;
}

.cover-container {
  height: 300px;
  width: 300px;

  cursor: pointer;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;
  border: white solid 2px;
  position: relative;
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  /* transform: scale(1); */
  transform-origin: bottom;
  transition: 0.4s ease;
}

.cover {
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
}

.cover-title {
  width: 300px;
  z-index: 4;
  position: absolute;
  /* top: 0; */
  left: 0;
  bottom: -30px;

  /* transform: scale(1); */
  transform-origin: bottom;
  transition: 0.4s ease;

  pointer-events: none;
}

#footer {
  width: 100%;
  /* height: 400px; */
  background-color: #a6882f;
  z-index: 1;
  margin-top: auto;
}

.sub-box {
  width: 100%;
  padding: 64px;
}

.social-icon {
  width: 64px;

  max-width: 75px;

  color: #000;

  background-color: #fff;

  border-color: #000;

  border-style: solid;

  border-top-width: 1px;

  border-left-width: 1px;

  border-right-width: 2px;

  border-bottom-width: 2px;

  font-size: 1.5rem;

  padding: 0.25rem;

  text-decoration: none;

  text-align: center;
  border-radius: 5px;

  margin: 4px;
}

.social-icon:hover {
  color: #fff;

  background-color: #911b23dc;
}

.esrbs {
  background-color: #911b23dc;
  width: 100%;
  justify-content: space-evenly;
}

.esrb-box {
  height: 50%;
  margin-top: 32px;
}

.esrb-subtitle {
  font-size: 1.2em;
}

.brand-icon {
  height: 70px;
  margin: 8px;
}

.brand-icon-sm {
  height: 24px;
  margin: 8px;
}

.nisa-icon {
  height: 50px;
  margin: 8px;
}

.footer-bottom {
  background-color: rgba(0, 0, 0, 0.863);
  width: 100%;
  padding: 32px;
}

.footer-text {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1em;
}

.brands {
  width: 90%;
}

.anim-box {
  position: absolute;
  bottom: 0px;
  width: 300px;
}

.banner {
  background-color: #5375b3;
  overflow: hidden;
  width: 300px;
  /* border: 1px solid green; */
}

.banner > h3 {
  font-size: 0.8em;
  color: white;
  /* padding: 0px 8px; */
  margin: 8px;
  font-family: optimus;
}
